<template>
    <div class="app">
        <van-skeleton
                :row="20"
                :loading="loading"
        >
            <div class="header-title">
                <span>{{(userInfo.customerRegistInfo.nickName||'') +'-'+(orderDetails.productType||'')+'-'+(orderDetails.orderNo||'')}}</span>
            </div>
            <order-info :review-order-info="orderDetails"></order-info>
            <user-info :user-info="userInfo.customerRegistInfo"></user-info>
            <div v-if="refundApplyInfo">
                <div class="row-box">
                    <div class="col-box all-col">退款理由：{{refundApplyInfo.refundReason||'暂无'}}</div>
                </div>
                <div class="row-box">
                    <div class="col-box all-col">退款金额：
                        <van-radio-group
                                direction="horizontal"
                                disabled
                                v-model="refundApplyInfo.refundType">
                            <van-radio :name="1">全额对款</van-radio>
                            <van-radio :name="2">指定金额退款</van-radio>
                        </van-radio-group>
                        {{refundApplyInfo.refundMoney}}
                    </div>
                </div>

                <div class="row-box">
                    <div class="col-box all-col">退款说明：{{refundApplyInfo.refundRemark}}</div>
                </div>
                <div class="row-box">
                    <div class="col-box all-col">上传附件：
                        <span v-if="refundApplyInfo.fileInfos&&refundApplyInfo.fileInfos.length>0">
                            <a v-for="(item,index) in refundApplyInfo.fileInfos" style="margin-right: 5px"
                               :key="index" :href="`${item.filePath}`">{{item.fileName}}</a>
                        </span>
                        <span v-else>暂无</span>
                    </div>
                </div>
                <div class="row-box">
                    <div class="col-box all-col">
                        审核评论：
                        <van-field v-model="refundApplyInfo.remark" type="textarea"></van-field>
<!--                        {{refundApplyInfo.refundRemark||'暂无'}}-->
                    </div>
                </div>
            </div>
            <div class="footer-box" v-if="refundApplyInfo&&refundApplyInfo.processStatus=='待审核'">
                <van-button size="mini"
                            :loading="passLoading"
                            type="warning"
                            @click="pass(2)">审核通过</van-button>
                <van-button size="mini"
                            style="margin-left: 10px"
                            :loading="noPassLoading"
                            @click="pass(3)">审核驳回</van-button>
            </div>
        </van-skeleton>
    </div>
</template>

<script>
    import { getOrderInfo,getOrderDetails,addApplyRefundProcessInfo} from '@/api/myOrderDetails/index.js'
    import orderInfo from './newComp/orderInfo'
    import userInfo from "./newComp/userInfo"
    export default {
        name: "refundOrderInfo",
        components:{
            orderInfo,
            userInfo
        },
        data(){
            return{
                loading:false,
                userInfo:{
                    customerRegistInfo:{
                        nickName:''
                    },
                },
                orderDetails:{},
                refundApplyInfo:{},
                passLoading:false,
                noPassLoading:false,
                orderNo:'',
                pushNo:''
            }
        },
        created() {
            this.orderNo = this.$route.query.orderNo
            this.pushNo = this.$route.query.pushNo
            this.onLoad()
        },
        methods:{
            onLoad(){
                this.loading = true
                getOrderInfo({
                    orderNo:this.orderNo,
                    pushNo:this.pushNo
                }).then(res=>{
                    getOrderDetails({
                        orderNo:this.orderNo,
                        pushNo:this.pushNo
                    }).then(res=>{
                        this.userInfo = res.data.userInfo
                        this.orderDetails = res.data.orderDetails
                        this.refundApplyInfo = res.data.refundApplyInfo
                        this.loading = false
                    }).catch(err=>{
                        this.$failToast('参数错误')
                        return
                        this.loading = false
                    })
                })

            },
            pass(status){
                let txt
                if(status==2){
                    txt = '审核通过'
                    this.passLoading = true
                }
                else if(status==3){
                    txt = '审核驳回'
                    this.noPassLoading = true
                }else{
                    return
                }
                addApplyRefundProcessInfo({
                    id:'',
                    attachmentId:'',
                    processUserId:'',
                    createDate:'',
                    orderNo:this.orderNo,
                    pushNo:this.pushNo,
                    applyId:this.refundApplyInfo.id,
                    status:status,
                    remark:this.refundApplyInfo.remark
                }).then(res=>{
                    if(res.success){
                        this.passLoading = false
                        this.noPassLoading = false
                        this.$toast(txt)
                        this.onLoad()
                    }else{
                        let txt = res.msg
                        this.$failToast(txt)
                        this.passLoading = false
                        this.noPassLoading = false
                    }
                }).catch(err=>{
                    this.$failToast('审核失败,'+res.msg)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    @media screen and (min-width: 800px) {
        .app {
            box-sizing: border-box;
            padding: 30px;
            width: 100% !important;
            background-color: #f9f9f9 !important;
            margin-top:1000px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%) scale(1.5);
            max-width: 1200px;
        }
    }
    .footer-box{
        width: 100%;
        height: 60px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
    }
    .header-title{
        font-size: 16px;
        font-weight: 700;
        padding: 15px;
    }
    .row-box{
        display: flex;
        justify-content: space-between;
        color:#000;
        font-size: 14px;
        /*background-color: red;*/
        font-weight: 700;
        padding-left: 10px;
        padding-right: 10px;
        .col-box{
            padding-top: 10px;
            padding-bottom: 10px;
            width: 50%;
            height: 100%;
            word-break: break-word;
            /*background-color: red;*/
        }
        .all-col{
            width: 100%;
        }
    }

    .van-button{
        min-width: 100px;
        height: 40px;
        font-size: 14px;
        border-radius: 4px;
    }
    td{
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>
