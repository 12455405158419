import { render, staticRenderFns } from "./userInfo.vue?vue&type=template&id=5fbd6c62&scoped=true&"
import script from "./userInfo.vue?vue&type=script&lang=js&"
export * from "./userInfo.vue?vue&type=script&lang=js&"
import style0 from "./userInfo.vue?vue&type=style&index=0&id=5fbd6c62&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fbd6c62",
  null
  
)

export default component.exports